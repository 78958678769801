import React, { useEffect, useState } from "react";
import moment from 'moment';
import { AiOutlineLike, AiFillLike, AiFillDislike, AiOutlineDislike, AiOutlinePushpin } from 'react-icons/ai';
import { Controller, useForm } from "react-hook-form";
import { BiCommentMinus } from 'react-icons/bi'
import { TbReportSearch } from 'react-icons/tb'

import { Row, Col, Input, Button, Label, Form, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, FormGroup, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip } from "reactstrap"
import { Menu } from "components/menu";
import { Likes } from "components/Likes";
import { SearchInput } from "components/search";
import { MenuItems } from "components/MenuItems";
import { AnchorScroll } from "helper";
import { toast } from 'react-toastify';
import { Service, Storage } from "services/service";
import Config from '../../config/Config'
import { useParams, Link, useHistory } from "react-router-dom";
import { MdDeleteForever } from 'react-icons/md'
import ReactPlayer from 'react-player';


const Feed = () => {
    const history = useHistory()

    const [data, setData] = useState([])
    const [factionData, setFactionData] = useState([])
    const [refresh, setRefresh] = useState(false)

    let userData = Storage.get("userData")

    const [pageLoading, setPageLoading] = useState(false)
    const [sectionLoading, setSectionLoading] = useState(false)

    //Data Store
    const [viewCommunityData, setViewCommunityData] = useState([])
    const [communityPostData, setCommunityPostData] = useState([])
    const [commentsData, setCommentsData] = useState([])

    //PostLike
    const [like, setLike] = useState([])
    const [dislikeButton, setDislikeButton] = useState([])
    const [likeCommentButton, setLikeCommentButton] = useState([])
    
    //Modal
    // const [editModal, setEditModal] = useState(false)
    // const [deleteModal, setDeleteModal] = useState(false)
    const [commentModal, setCommentModal] = useState(false)
    const [reportModal, setReportModal] = useState(false)
    // const [videoModal, setVideoModal] = useState(false)

    //Modal Popup
    const [popup, setPopup] = useState()

    //Params
    const paramsId = useParams()

    // use form
    const { control, handleSubmit, setValue } = useForm();
    
    //Modal Toggle
    // const editToggle = () => setEditModal(!editModal)
    // const deleteToggle = () => setDeleteModal(!deleteModal)
    const commentToggle = () => setCommentModal(!commentModal)
    const reportToggle = () => setReportModal(!reportModal)
    // const videoToggle = () => setVideoModal(!videoModal)
    
    //ToolTip
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    const reportPost = (item) => {
        reportToggle();
        setReportIndex(item.id)
    }

    const viewCommunityDetails = () => {
        setSectionLoading(true)
        Service.get({ url: `/activity/groups/${paramsId.id}`, body: JSON.stringify() })
            .then((response) => {
                setViewCommunityData(response.data)
                setSectionLoading(false)
            }).catch((error) => {
                setSectionLoading(false)
            })
    }

    useEffect(() => {
        viewCommunityDetails();
    }, [paramsId])

    // CRUD Index
    // const [editIndex, setEditIndex] = useState()
    // const [deleteIndex, setDeleteIndex] = useState()
    const [commentIndex, setCommentIndex] = useState()
    const [reportIndex, setReportIndex] = useState()
    
    useEffect(() => {
        getAllNotifications(true);
        AnchorScroll();
        viewFactionDetails();
    }, [])

    const viewFactionDetails = () => {
        Service.get({ url: `/activity/groups/factionGroup/${userData.faction}`, body: JSON.stringify() })
            .then((response) => {
                setFactionData(response.data)
            }).catch((error) => { })
    }

    const getAllPosts = (item) => {
        const params = {
            limit: 50,
            page: 1,
            order: "desc",
            groupId: paramsId.id
        }
        setPageLoading(item)
        Service.post({ url: '/activity/posts/search', body: JSON.stringify(params) })
            .then((response) => {
                setCommunityPostData(response.data)
                setPageLoading(false)
            }).catch((error) => {
                setPageLoading(false)
            })
    }

    const onCommentSubmit = (data) => {
        const postid = commentIndex;
        const message = data.Comment;
        const params = {
            postId: postid,
            comment: message
        }
        const params1 = {
            postId: postid.post_id,
            comment: message
        }
        if (popup === "Edit") {
            Service.patch({ url: `/activity/comments/${postid.id}`, body: JSON.stringify(params1) })
                .then((response) => {
                    setValue("Comment", "")
                    commentPost(postid.post_id, false);
                    getAllPosts(null);
                }).catch((error) => { })
        } else {
            Service.post({ url: '/activity/comments', body: JSON.stringify(params) })
                .then((response) => {
                    setValue("Comment", "")
                    commentPost(postid, false);
                    getAllPosts(null);
                }).catch((error) => { })
        }
    }

    const getAllNotifications = (item) => {
        setPageLoading(item)
        Service.post({ url: '/activity/feeds/search', body: JSON.stringify() })
            .then((response) => {
                setData(response.data)
                setPageLoading(false)
            }).catch((error) => {
                setPageLoading(false)
            })
    }

    const viewNotification = (item) => {
        Service.get({ url: `/activity/notification/${item}`, body: JSON.stringify() })
            .then((response) => { }).catch((error) => { })
    }

    const deleteNotification = (item) => {
        Service.delete({ url: `/activity/notification/${item}`, body: JSON.stringify() })
            .then((response) => {
                getAllNotifications(null);
                setRefresh(true)
            }).catch((error) => { })
    }

    const editComment = (item) => {
        setPopup("Edit")
        setValue("Comment", item.message)
        setCommentIndex(item)
    }

    const onReportSubmit = () => {
        const params = {
            postId: reportIndex
        }
        Service.post({ url: '/activity/posts/report', body: JSON.stringify(params) })
            .then((response) => {
                reportToggle();
                showToastMessageSuccess("Post reported successfully")
            }).catch((error) => { })
    }

    const showToastMessageSuccess = (info) => {
        toast.success(info, {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    const deleteComment = (item) => {
        Service.delete({ url: `/activity/comments/${item.id}`, body: JSON.stringify() })
            .then((response) => {
                commentPost(item.post_id, false);
                getAllPosts(null);
            }).catch((error) => { })
    }

    const userProfile = (item) => {
        if (item.user === userData.id) {
            history.push(`/home`)
        } else {
            history.push(`/home/${item.user}`)
        }
    }

    const communityClick = (item) => {
        history.push(`/community/${item.groupId}`)
    }
    
    const userCommentProfile = (item) => {
        if (item.user_id === userData.id) {
            history.push(`/home`)
        } else {
            history.push(`/home/${item.user_id}`)
        }
    }

    function navigate(event) {
        event.preventDefault()
        if (event.target.tagName === 'A') {
            history.push(event.target.getAttribute('href'))
        }
    }

    const likePost = (item) => {
        const postid = item.id;
        const userid = userData.id
        let index = like.findIndex((x) => x === postid);
        if (item.isLiked > 0) {
            like.splice(index, 1);
            Service.delete({ url: `/activity/postsLike/${postid}`, body: JSON.stringify() })
                .then((response) => {
                    getAllNotifications(true)
                }).catch((error) => { })
        }
        else {
            like.push(item.id);
            const params = {
                postId: postid,
                userId: userid
            }
            Service.post({ url: '/activity/postsLike', body: JSON.stringify(params) })
                .then((response) => {
                    getAllNotifications(true)
                }).catch((error) => { })
        }
        setLike([...like]);
    }

    useEffect( () => {
        console.log("likes", like, "dislikes", dislikeButton)
    }, [like, dislikeButton])

    const dislikePost = (item) => {
        const postid = item.id;
        let index = dislikeButton.findIndex((x) => x === item.id);
        if (item.isDisliked > 0) {
            dislikeButton.splice(index, 1);
            Service.delete({ url: `/activity/postsDislike/${postid}`, body: JSON.stringify() })
                .then((response) => {
                    getAllNotifications(null);
                }).catch((error) => { })
        }
        else {
            dislikeButton.push(item.id);
            const params = {
                postId: postid
            }
            Service.post({ url: '/activity/postsDislike', body: JSON.stringify(params) })
                .then((response) => {
                    getAllNotifications(null);
                }).catch((error) => { })
        }
        
        setDislikeButton([...dislikeButton]);
    }

    const commentPost = (item, type = true) => {
        const params = {
            limit: 50,
            page: 1,
            order: "desc",
            postId: item
        }
        if (type === true) {
            setValue("Comment", "")
            commentToggle();
            setCommentIndex(item)
        }
        Service.post({ url: '/activity/comments/search', body: JSON.stringify(params) })
            .then((response) => {
                setCommentsData(response.data)
            }).catch((error) => { })
    }

    const likeComment = (item) => {
        const commentid = item.id;
        const userid = userData.id
        let index = likeCommentButton.findIndex((x) => x === item.id);
        if (item.isliked > 0) {
            likeCommentButton.splice(index, 1);
            Service.delete({ url: `/activity/commentsLike/${commentid}`, body: JSON.stringify() })
                .then((response) => {
                    commentPost(item.post_id, false);
                }).catch((error) => { })
        }
        else {
            likeCommentButton.push(item.id);
            const params = {
                commentId: commentid,
                userId: userid
            }
            Service.post({ url: '/activity/commentsLike', body: JSON.stringify(params) })
                .then((response) => {
                    commentPost(item.post_id, false);
                }).catch((error) => { })
        }
        setLikeCommentButton([...likeCommentButton]);
    }

    return (
        <>
            <div className="px-4 bg-right">
                <SearchInput refresh={refresh} />
                <Row>
                    <Col xl="3" lg="3" md="12" sm="12" xs="12" className="mt-4">
                        <div className="mx-sm-5 px-sm-5 mx-lg-0 px-lg-0">
                            <Menu />
                        </div>
                        <div className="d-none d-lg-block mt-lg-4 stickey">
                            <MenuItems />
                        </div>
                    </Col>
                    <Col xl="6" lg="6" md="12" sm="12" xs="12" >
                        <div className="middle-info-bg h-lg-100 p-2 p-xl-4 mt-4">
                            <h3 className="mb-1 px-3 px-sm-0">Babble</h3>
                            {
                                pageLoading ? <div className="bg-primary p-3 m-3 text-center"><Spinner color="light" /></div> :
                                    data && data.list && data.list.length > 0 ?
                                        data && data.list && data.list.length > 0 && data.list.map((item) => (
                                        
                                                
                                                    // old code
                                                    // item.type === "group" ? <></> : <div className="bg-primary px-3 px-sm-0 my-3 mx-1">
                                                    //     <Row className="py-2">
                                                    //         <Col xl="12" lg="12" md="12" xs="12">
                                                    //             {
                                                    //                 item.groupId === 0 && userData.r === "r" && <Link to={`/home/#${item.postId}`} onClick={() => viewNotification(item.id)}>
                                                    //                     <Row>
                                                    //                         <Col xl="3" lg="3" md="3" xs="3" className="d-flex justify-content-center feed-border-right">
                                                    //                             <div className={item.SenderProfileImage ? "avatar-img3" : "avatar-img3-original"}>
                                                    //                                 <img src={item.SenderProfileImage ? `${Config.MEDIA_URL}${item.SenderProfileImage}` : require('../../assets/img/Landing_Page/logo.png')} alt="avatar" className=" avatar-imginside"
                                                    //                                     width={"80%"} height="80%" />
                                                    //                             </div>
                                                    //                         </Col>
                                                    //                         <Col xl="9" lg="9" md="9" xs="12" className="d-flex align-items-center">
                                                    //                             <div className="d-flex flex-column flex-sm-row flex-lg-column flex-xl-row justify-content-center justify-content-xl-start align-items-center">
                                                    //                                 <h6 className="d-inline"><h6 className="noti-name-color d-inline">{item.SenderName ? item.SenderName : `User ${item.sender}`}</h6> has <h6 className="d-inline">{item.type === "comment" && "Commented on your Post" || item.type === "p_Like" && "Liked your Post" || item.type === "p_dislike" && "Disliked your Post" || item.type === "c_Like" && "Liked your Comment" || item.type === "post" && "Posted on your Community"}</h6></h6>
                                                    //                             </div>
                                                    //                         </Col>
                                                    //                     </Row>
                                                    //                 </Link> || item.groupId === factionData.id && userData.r === "r" && <Link to={`/faction/#${item.postId}`} onClick={() => viewNotification(item.id)}>
                                                    //                     <Row>
                                                    //                         <Col xl="3" lg="3" md="3" xs="12" className="d-flex justify-content-center feed-border-right">
                                                    //                             <div className={item.SenderProfileImage ? "avatar-img3" : "avatar-img3-original"}>
                                                    //                                 <img src={item.SenderProfileImage ? `${Config.MEDIA_URL}${item.SenderProfileImage}` : require('../../assets/img/Landing_Page/logo.png')} alt="avatar" className="avatar-imginside"
                                                    //                                     width={"80%"} height="80%" />
                                                    //                             </div>
                                                    //                         </Col>
                                                    //                         <Col xl="9" lg="9" md="9" xs="12" className="d-flex align-items-center">
                                                    //                             <div className="d-flex flex-column flex-sm-row flex-lg-column flex-xl-row justify-content-center justify-content-xl-start align-items-center">
                                                    //                                 <h6 className="d-inline"><h6 className="noti-name-color d-inline">{item.SenderName ? item.SenderName : `User ${item.sender}`}</h6> has <h6 className="d-inline">{item.type === "comment" && "Commented on your Post" || item.type === "p_Like" && "Liked your Post" || item.type === "p_dislike" && "Disliked your Post" || item.type === "c_Like" && "Liked your Comment" || item.type === "post" && "Posted on your Community"}</h6></h6>
                                                    //                             </div>
                                                    //                         </Col>
                                                    //                     </Row>
                                                    //                 </Link> || <Link to={`/community/${item.groupId}/#${item.postId}`} onClick={() => viewNotification(item.id)}>
                                                    //                     <Row>
                                                    //                         <Col xl="2" lg="2" md="2" xs="3" className="d-flex justify-content-center feed-border-right">
                                                    //                             <div className={item.SenderProfileImage ? "avatar-img3" : "avatar-img3-original"}>
                                                    //                                 <img src={item.SenderProfileImage ? `${Config.MEDIA_URL}${item.SenderProfileImage}` : require('../../assets/img/Landing_Page/logo.png')} alt="avatar" className=" avatar-imginside"
                                                    //                                     width={"80%"} height="80%" />
                                                    //                             </div>
                                                    //                         </Col>
                                                    //                         <Col xl="8" lg="8" md="8" xs="6" className="d-flex align-items-center">
                                                    //                             <div className="d-flex flex-column flex-sm-row flex-lg-column flex-xl-row justify-content-center justify-content-xl-start align-items-center">
                                                    //                                 <h6 className="d-inline"><h6 className="noti-name-color d-inline">{item.SenderName ? item.SenderName : `User ${item.sender}`}</h6> has <h6 className="d-inline">{item.type === "comment" && "Commented on your Post" || item.type === "p_Like" && "Liked your Post" || item.type === "p_dislike" && "Disliked your Post" || item.type === "c_Like" && "Liked your Comment" || item.type === "post" && "Posted on your Community"}</h6></h6>
                                                    //                             </div>
                                                    //                         </Col>
                                                    //                         <Col xl="2" lg="2" md="2" xs="3" className="d-flex justify-content-center align-items-center">
                                                    //                         <MdDeleteForever color="#fea321" className="cursor-pointer" size={"2em"} onClick={() => deleteNotification(item.id)} />
                                                    //                     </Col>
                                                    //                     </Row>
                                                    //                 </Link>
                                                    //             }
                                                    //         </Col>
                                                    //     </Row>
                                                    // </div>

                                                    <>
                                                        <div className="bg-primary m-2 m-sm-3" id={item.id}>
                                                            <div className="d-flex align-items-center justify-content-between py-3 pl-3 pr-3">
                                                                <div className="d-flex align-items-center justify-content-start cursor-pointer" onClick={() => userProfile(item)}>
                                                                    <div className={item.userProfileImage ? "avatar-img1" : "avatar-img1-original"}>
                                                                        <img src={item.userProfileImage ? `${Config.MEDIA_URL}${item.userProfileImage}` : require('../../assets/img/Landing_Page/logo.png')} className="avatar-imginside" alt="" />
                                                                    </div>
                                                                    <div className="ml-2">
                                                                        <h4 className="mb-0">{item.userName ? item.userName : `USER ${item.user}`}</h4>
                                                                        <span>Posted at {moment(item.date).format('LLL')}</span>
                                                                        <div onClick={(e) => { e.stopPropagation(); e.preventDefault(); communityClick(item) }}><p> Posted in Community <h6 style={{display: 'inline' }}>{item.groupName}</h6>  </p></div>

                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-row align-items-center justify-content-start">
                                                                    <div className="mb-3">
                                                                        {item.isPin > 0 && <AiOutlinePushpin color="#FFF" size={30} />}
                                                                    </div>
                                                                    {/* <UncontrolledDropdown className='dropdown-user nav-item'>
                                                                        <DropdownToggle href='/' tag='a' className='align-items-center' onClick={e => e.preventDefault()} >
                                                                            {
                                                                                ((item.user === userData.id && viewCommunityData.canAccess === 1) || (viewCommunityData.GroupOwner === userData.id && viewCommunityData.canAccess === 1 && item.isAdmin === 0)) || (userData.r === "n" && viewCommunityData.canAccess === 1) ? <img src={require('../../assets/img/Landing_Page/menu1.png')} className="mr-3 mb-3" alt="" /> : <></>
                                                                            }
                                                                        </DropdownToggle>
                                                                        {
                                                                            userData && userData.r === "n" ? <DropdownMenu className="dropdown-menu-right optionpost">
                                                                                <DropdownItem id={index} key={index} to='#' onClick={() => editPost(item)}>
                                                                                    <span className='align-middle optionpostitem'>Edit Post</span>
                                                                                </DropdownItem>
                                                                                <DropdownItem to='#' onClick={() => deletePost(item)}>
                                                                                    <span className='align-middle optionpostitem'>Delete Post</span>
                                                                                </DropdownItem>
                                                                            </DropdownMenu> : <DropdownMenu className="dropdown-menu-right optionpost">
                                                                                {item && item.isPin === 0 ? <DropdownItem to='#' onClick={() => pinPost(item)}>
                                                                                    <span className='align-middle optionpostitem'>Pin Post</span>
                                                                                </DropdownItem> : <DropdownItem to='#' onClick={() => unPinPost(item)}>
                                                                                    <span className='align-middle optionpostitem'>Un Pin Post</span>
                                                                                </DropdownItem>}
                                                                                <DropdownItem id={index} key={index} to='#' onClick={() => editPost(item)}>
                                                                                    <span className='align-middle optionpostitem'>Edit Post</span>
                                                                                </DropdownItem>
                                                                                <DropdownItem to='#' onClick={() => deletePost(item)}>
                                                                                    <span className='align-middle optionpostitem'>Delete Post</span>
                                                                                </DropdownItem>
                                                                            </DropdownMenu>
                                                                        }
                                                                    </UncontrolledDropdown> */}
                                                                </div>
                                                            </div>
                                                            <p onClick={(e) => navigate(e)} dangerouslySetInnerHTML={{ __html: item.postMessage.split("[/1319/]")[0] }} className="mx-4 mb-4 text-capitalize text-justify"></p>
                                                            {
                                                                item.postMessage.split("[/1319/]")[1] === "null" || item.postMessage.split("[/1319/]")[1] === undefined ? <></> : <img src={`${Config.MEDIA_URL}${item.postMessage.split("[/1319/]")[1]}`} className="px-3 pb-3 image-width" height={'fit-content'} alt="" />
                                                            }
                                                            {
                                                                item.postMessage.split("[/1319/]")[2] === "null" || item.postMessage.split("[/1319/]")[2] === undefined ? <></> : <ReactPlayer className="px-3 image-width rounded" url={item.postMessage.split("[/1319/]")[2]} controls={true} width={"100%"}> 
                                                                        
                                                                </ReactPlayer>
                                                            }
                                                            { 
                                                                <Row className="mx-3 mt-4 actiongrid py-2 px-2">
                                                                
                                                                    <Col className="d-flex flex-sm-row flex-column justify-content-sm-between justify-content-center align-items-center" lg={4} md={4} sm={4}
                                                                        xs={12}>
                                                                        <a href="/" onClick={(e) => e.preventDefault()} className="actiontypes">{item.likes} {item.likes > 1 ? "Likes" : "Like"}</a>
                                                                        <a href="/" onClick={(e) => e.preventDefault()} className="actiontypes">{item.dislikes} {item.dislikes > 1 ? "Dislikes" : "Dislike"}</a>
                                                                    </Col>
                                                                    <Col className="d-flex flex-sm-row flex-column justify-content-center justify-content-sm-end align-items-center" lg={8} md={8} sm={8} xs={12} onClick={() => commentPost(item.id)}>
                                                                        <a href="/" onClick={(e) => { e.preventDefault(); }} className="pr-md-5 actiontypes">{item.comments} {item.comments > 1 ? "Comments" : "Comment"}</a>
                                                                    </Col>
                                                                
                                                                </Row> 
                                                            }
                                                            <Row className="mt-3 pb-4 mx-1">
                                                                {
                                                                    <>
                                                                        <Col className="d-flex justify-content-center align-items-center cursor-pointer" onClick={() => likePost(item)}>
                                                                            {
                                                                                item.likes > 0 ? <AiFillLike color="#00FF00" size={"2em"} /> : <AiOutlineLike size={"2em"} />
                                                                            }  
                                                                            <h5 className={`ml-1 mb-0 ${item.likes > 0 ? "like-color" : "text-muted"}`}>Dope</h5>
                                                                        </Col>
                                                                        <Col className="d-flex justify-content-center align-items-center cursor-pointer" onClick={() => dislikePost(item)}>
                                                                            {
                                                                                item.dislikes > 0 ? <AiFillDislike color="#FF0000" size={"2em"} /> : <AiOutlineDislike size={"2em"} />
                                                                            }
                                                                            <h5 className={`ml-1 mb-0 ${item.dislikes > 0 ? "dislike-color" : "text-muted"}`}>Wack</h5>
                                                                        </Col>
                                                                        <Col className="d-flex justify-content-center align-items-center cursor-pointer" onClick={() => commentPost(item.id)}>
                                                                            <BiCommentMinus size={"2em"} />
                                                                            <h5 className="ml-1 mb-0 text-muted">Comment</h5>
                                                                        </Col>
                                                                        <Col className="d-flex justify-content-center align-items-center cursor-pointer" onClick={() => reportPost(item)}>
                                                                            <TbReportSearch size={"2em"} />
                                                                            <h5 className="ml-1 mb-0 text-muted">Report</h5>
                                                                        </Col>
                                                                    </>
                                                                }
                                                            </Row>
                                                        </div>

                                                    
                            </>

                                                
                                        
                                        )) : <div className="bg-primary p-3 m-3 text-center">
                                            <h6 className="mb-0">No Babble to show</h6>
                                        </div>
                            }
                        </div>
                        {userData && userData.id ? <>
                                                        {/* <------- Comment Modal ------------> */}

                                                        <Modal isOpen={commentModal} toggle={commentToggle}>
                                    <ModalHeader toggle={commentToggle}>Comments</ModalHeader>
                                    <hr className="w-100 modal-line" />
                                    <div className="scrolling-enable1">
                                        <ModalBody>
                                            {
                                                commentsData && commentsData.length > 0 ? commentsData.map((item) => {
                                                    return <FormGroup>
                                                        <Row className="d-flex align-items-center" id={item.id}>
                                                            <Col lg={2} md={2} sm={2} xs={3} className='mb-3 d-flex cursor-pointer justify-content-center' onClick={() => userCommentProfile(item)}>
                                                                <div className={item.UserProfileImage ? "avatar-img3" : "avatar-img3-original"}>
                                                                    <img src={item.UserProfileImage ? `${Config.MEDIA_URL}${item.UserProfileImage}` : require('../../assets/img/Landing_Page/logo.png')} className="avatar-imginside" alt="" />
                                                                </div>
                                                            </Col>
                                                            <Col lg={7} md={7} sm={7} xs={6} className="justify-content-start p-0 sd">
                                                                <Col className="comment-bg">
                                                                    <h6>{item.userName ? item.userName : `USER ${item.user_id}`}</h6>
                                                                    <span className="text-muted">{item.message}</span>
                                                                </Col>
                                                                <Col className="d-flex justify-content-start" lg={12}>
                                                                    <a href="/" onClick={(e) => e.preventDefault()} className="actiontypes">{item.likes} {item.likes > 1 ? "Likes" : "Like"}</a>
                                                                </Col>
                                                            </Col>
                                                            {
                                                                <Col lg={2} md={2} sm={2} xs={2} id="TooltipExample" className='cursor-pointer mb-3' onClick={() => likeComment(item)}>
                                                                    <Tooltip
                                                                        isOpen={tooltipOpen}
                                                                        target="TooltipExample"
                                                                        toggle={toggle}
                                                                    >
                                                                        Like Comment
                                                                    </Tooltip>
                                                                    {
                                                                        item.isliked > 0 ? <AiFillLike className="com-like-button" size={"35px"} /> : <AiOutlineLike className="com-like-button1" size={"35px"} />
                                                                    }
                                                                </Col>
                                                            }

                                                            {
                                                                (item.user_id === userData.id) ? <Col lg={1} md={1} sm={1} xs={1} className="p-0">
                                                                    <UncontrolledDropdown className='dropdown-user nav-item' direction="down">
                                                                        <DropdownToggle href='/' tag='a' className='align-items-center' onClick={e => e.preventDefault()} >
                                                                            <img src={require('.././../assets/img/Landing_Page/menu1.png')} className="mr-3 mb-3" alt="" />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu container="body" className="dropdown-menu-right optionpost">
                                                                            <DropdownItem to='#' onClick={() => editComment(item)}>
                                                                                <span className='align-middle optionpostitem'>Edit Comment</span>
                                                                            </DropdownItem>
                                                                            <DropdownItem to='#' onClick={() => deleteComment(item)}>
                                                                                <span className='align-middle optionpostitem'>Delete Comment</span>
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </Col> : <span></span>
                                                            }
                                                        </Row>
                                                    </FormGroup>
                                                }) : <div className="text-center"><span>No Comments yet</span></div>
                                            }
                                        </ModalBody>
                                    </div>
                                    <hr className="w-100 modal-line" />
                                    <ModalFooter className="py-0 main-modal-comment d-flex align-items-center justify-content-center">
                                        <Form onSubmit={handleSubmit(onCommentSubmit)}>
                                            {
                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} md={3} sm={3} xs={12} className="pl-sm-3"><div className={userData.avatar ? "avatar-img3" : "avatar-img3-original"}><img src={userData.avatar ? `${Config.MEDIA_URL}${userData.avatar}` : require('../../assets/img/Landing_Page/logo.png')} className="avatar-imginside" alt="" /></div></Col>
                                                    <Col lg={7} md={6} sm={6} xs={9} className='p-0 mt-2'>
                                                        <FormGroup>
                                                            <Controller
                                                                name="Comment"
                                                                control={control}
                                                                id="comment"
                                                                render={({ field }) => <Input placeholder="Write a Comment" type="text" {...field} />}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={3} md={3} sm={3} xs={3} className="text-right">
                                                        <Button color="success" type="submit">
                                                            Post
                                                        </Button>{' '}
                                                    </Col>
                                                </Row>
                                            }
                                        </Form>
                                    </ModalFooter>
                                </Modal>

                                {/* <------- Report Modal ------------> */}

                                <Modal isOpen={reportModal} toggle={reportToggle}>
                                    <ModalHeader toggle={reportToggle}>Report</ModalHeader>
                                    <hr className="w-100 modal-line" />
                                    <ModalBody>
                                        <Form onSubmit={handleSubmit(onReportSubmit)}>
                                            <FormGroup>
                                                <Label for="report">
                                                    Do you want to report this post ?
                                                </Label>
                                            </FormGroup>
                                            <ModalFooter>
                                                <Button color="success" type="submit">
                                                    Yes
                                                </Button>{' '}
                                                <Button color="secondary" onClick={reportToggle}>
                                                    No
                                                </Button>
                                            </ModalFooter>
                                        </Form>
                                    </ModalBody>
                                </Modal>

                        </> : <></> }
                    </Col>
                    {/* <------- Joinned comunity third column in all pages ------------> */}
                    <Col xl="3" lg="3" md="12" sm="12" xs="12" className="p-0 my-5 mt-sm-0">
                        <Likes />
                    </Col>
                </Row>
            </div >
        </>
    )
}

export default Feed