import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { Row, Col, Input, Button, Form, FormGroup, Label, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, Spinner } from "reactstrap"
import { Menu } from "components/menu";
import { Likes } from "components/Likes";
import { SearchInput } from "components/search";
import { MenuItems } from "components/MenuItems";
import { useParams, Link, useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Service, Storage } from "services/service";
import { AiOutlineLike, AiFillLike, AiFillDislike, AiOutlineDislike, AiOutlinePushpin } from 'react-icons/ai';
import { BiCommentMinus } from 'react-icons/bi'
import { TbReportSearch } from 'react-icons/tb'
import { AnchorScroll } from "helper";
import Config from '../../config/Config';
import ReactPlayer from 'react-player';
import moment from 'moment'
import Avatar from "react-avatar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiImageAddFill } from "react-icons/ri";
import FileAvatar from "components/FileAvatar";
import { EditorState, ContentState, convertFromHTML, CompositeDecorator } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, { defaultSuggestionsFilter } from '@draft-js-plugins/mention';
import { stateToHTML } from 'draft-js-export-html';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css'; // Import the plugin's CSS file
import '@draft-js-plugins/mention/lib/plugin.css';
import { stateFromHTML } from 'draft-js-import-html';
import htmlToDraft from 'html-to-draftjs';


const Faction = () => {

    const ref = useRef(null);
    const editref = useRef(null)

    //User Profile
    let userData = Storage.get("userData")
    const history = useHistory()

    const [adminData, setAdminData] = useState([])
    const [factionData, setFactionData] = useState([])
    const [factionPost, setFactionPost] = useState([])
    const [commentsData, setCommentsData] = useState([])

    const [editModal, setEditModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [commentModal, setCommentModal] = useState(false)
    const [reportModal, setReportModal] = useState(false)
    const [videoModal, setVideoModal] = useState(false)

    const [editCommunity, setEditCommunity] = useState(false)
    const [editCommuntiyIndex, setEditCommuntiyIndex] = useState([])
    const [editImage, setEditImage] = useState("")

    //PostLike
    const [like, setLike] = useState([])
    const [dislikeButton, setDislikeButton] = useState([])
    const [likeCommentButton, setLikeCommentButton] = useState([])
    //mentions
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [editEditorState, setEditEditorState] = useState(() => EditorState.createEmpty());
    const [suggestions, setSuggestions] = useState([]);
    const [editSuggestions, setEditSuggestions] = useState([]);
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);

    //Modal Toggle
    const editToggle = () => setEditModal(!editModal)
    const deleteToggle = () => setDeleteModal(!deleteModal)
    const commentToggle = () => setCommentModal(!commentModal)
    const reportToggle = () => setReportModal(!reportModal)
    const videoToggle = () => setVideoModal(!videoModal)
    const editCommunityToggle = () => setEditCommunity(!editCommunity)

    // CRUD Index
    const [editIndex, setEditIndex] = useState()
    const [deleteIndex, setDeleteIndex] = useState()
    const [commentIndex, setCommentIndex] = useState()
    const [reportIndex, setReportIndex] = useState()

    //Post Edit
    const [editValue, setEditValue] = useState()

    //Modal Popup
    const [popup, setPopup] = useState()

    //ToolTip
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    //File Upload
    const [resultFiles, setResultFiles] = useState()
    const [resultVideoFiles, setVideoResultFiles] = useState()

    const [loading, setLoading] = useState(false)
    const params = useParams()

    const { control, handleSubmit, setValue } = useForm();

    const [pageLoading, setPageLoading] = useState(false)
    const [sectionLoading, setSectionLoading] = useState(false)

    const showToastMessageSuccess = (info) => {
        toast.success(info, {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    const showToastMessageError = (info) => {
        toast.error(info, {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    const options = {
        entityStyleFn: (entity) => {
            const entityType = entity.get("type").toLowerCase();

            if (entityType === "mention") {
                const { mention } = entity.get("data");
                return {
                    element: "a",
                    attributes: {
                        userid: mention.id,
                        href: mention.id === userData.id ? `/home` : `/home/${mention.id}`,
                    },
                };
            }
        },
    };

    useEffect(() => {
        factionGroups(true);
        AnchorScroll();
        viewFactionDetails();
        getAllFactionPosts(true);
    }, [])

    const { MentionSuggestions, plugins } = useMemo(() => {
        const mentionComponent = ({ entityKey, mention, className, decoratedText }) => {
            return <Link className={className} to={mention.id === userData.id ? `/home` : `/home/${mention.id}`}>{decoratedText}</Link>
        };
        const mentionPlugin = createMentionPlugin({
            mentionTrigger: "@", mentionComponent
        });
        const MentionSuggestions = mentionPlugin.MentionSuggestions;

        const plugins = [mentionPlugin];
        return { plugins, MentionSuggestions };
    }, []);

    const { plugins1, MentionSuggestions2 } = useMemo(() => {
        const mentionComponent1 = ({ entityKey, mention, className, decoratedText }) => {
            return <Link className={className} to={mention.id === userData.id ? `/home` : `/home/${mention.id}`}>{decoratedText}</Link>
        };
        const mentionPlugin2 = createMentionPlugin({
            mentionTrigger: "@", mentionComponent1
        });
        const MentionSuggestions2 = mentionPlugin2.MentionSuggestions;

        const plugins1 = [mentionPlugin2];
        return { plugins1, MentionSuggestions2 };
    }, []);

    const onOpenChange = useCallback((_open) => {
        setOpen(_open);
    }, []);
    const onEditOpenChange = useCallback((_open) => {
        setEditOpen(_open);
    }, []);

    const onSearchChange = useCallback(({ value }) => {
        if (value) {
            const params = {
                limit: 50,
                page: 1,
                order: "desc",
                name: value
            }
            Service.post({ url: '/activity/userandgroup/search', body: JSON.stringify(params) })
                .then((response) => {
                    const answer = response.data.filter((item) => item.type === "userName").map((item) => {
                        if (item.image === null) {
                            return { id: item.id, display: item.name, name: item.name, avatar: `${require('../../assets/img/Landing_Page/logo.png')}` }
                        } else {
                            return { id: item.id, display: item.name, name: item.name, avatar: `${Config.MEDIA_URL}${item.image}` }
                        }
                    })
                    setSuggestions(defaultSuggestionsFilter(value, answer));
                }).catch((error) => { })
        }
    }, []);

    const onSearchEditChange = useCallback(({ value }) => {
        if (value) {
            const params = {
                limit: 50,
                page: 1,
                order: "desc",
                name: value
            }
            Service.post({ url: '/activity/userandgroup/search', body: JSON.stringify(params) })
                .then((response) => {
                    const answer = response.data.filter((item) => item.type === "userName").map((item) => {
                        if (item.image === null) {
                            return { id: item.id, display: item.name, name: item.name, avatar: `${require('../../assets/img/Landing_Page/logo.png')}` }
                        } else {
                            return { id: item.id, display: item.name, name: item.name, avatar: `${Config.MEDIA_URL}${item.image}` }
                        }
                    })
                    setEditSuggestions(defaultSuggestionsFilter(value, answer));
                }).catch((error) => { })
        }
    }, []);

    const factionGroups = (item) => {
        const params = {
            limit: 1000,
            page: 1,
            order: "desc",
        }
        setPageLoading(item)
        Service.post({ url: '/activity/discover/search', body: JSON.stringify(params) })
            .then((response) => {
                const result = response.data.filter((item) => item.isFactionGroup >= 1)
                setAdminData(result)
                setPageLoading(false)
            }).catch((error) => {
                setPageLoading(false)
            })
    }

    const viewFactionDetails = () => {
        setSectionLoading(true)
        Service.get({ url: `/activity/groups/factionGroup/${userData.faction}`, body: JSON.stringify() })
            .then((response) => {
                setFactionData(response.data)
                setSectionLoading(false)
            }).catch((error) => {
                setSectionLoading(false)
            })
    }

    const getAllFactionPosts = (item) => {
        const params = {
            factionNo: userData.faction
        }
        setPageLoading(item)
        Service.post({ url: '/activity/posts/factionSearch', body: JSON.stringify(params) })
            .then((response) => {
                setFactionPost(response.data)
                setPageLoading(false)
            }).catch((error) => {
                setPageLoading(false)
            })
    }

    const fileUploading = async (files) => {
        try {
            if (!files.name.match(/.(jpg|jpeg|png)$/i)) {
                showToastMessageError('Allowed formats: JPG, JPEG and PNG')
                return false
            }
            const formData = new FormData()
            var myHeaders = new Headers();
            formData.append("file", files)

            const token = window.localStorage.getItem('token')
            if (token) {
                myHeaders.append("x-access-token", token)
            }
            setLoading(true)
            const result = await fetch(`${Config.API_BASE_URL}/user/fileUpload`, {
                method: "POST",
                headers: myHeaders,
                body: formData,
                redirect: 'follow'
            })
            if (result) {
                const response = await result.json()
                setResultFiles(response.data)
                showToastMessageSuccess("Image Uploaded")
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
        }
    }

    const editPost = (item) => {
        editToggle();
        const blocksFromHtml = htmlToDraft(item.postMessage.split('[/1319/]')[0]);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorStates = EditorState.createWithContent(contentState);
        setEditEditorState(editorStates)
        setValue("videoMessage", item.postMessage.split('[/1319/]')[2] === "null" ? "" : item.postMessage.split('[/1319/]')[2])
        setEditIndex(item.id)
        setEditValue(item.postMessage.split('[/1319/]')[1])
    }

    const deletePost = (item) => {
        deleteToggle();
        setDeleteIndex(item)
    }

    const reportPost = (item) => {
        reportToggle();
        setReportIndex(item.id)
    }

    const editCommunityDetails = (item) => {
        editCommunityToggle();
        setValue("GroupName", item.name)
        setValue("GroupDescription", item.description)
        setEditCommuntiyIndex(item.id)
        setEditImage(item.image)
    }

    const commentPost = (item, type = true) => {
        const params = {
            limit: 1000,
            page: 1,
            order: "desc",
            postId: item
        }
        if (type === true) {
            setValue("Comment", "")
            commentToggle();
            setCommentIndex(item)
        }
        Service.post({ url: '/activity/comments/search', body: JSON.stringify(params) })
            .then((response) => {
                setCommentsData(response.data)
            }).catch((error) => { })
    }

    const editComment = (item) => {
        setPopup("Edit")
        setValue("Comment", item.message)
        setCommentIndex(item)
    }

    const deleteComment = (item) => {
        Service.delete({ url: `/activity/comments/${item.id}`, body: JSON.stringify() })
            .then((response) => {
                commentPost(item.post_id, false);
                getAllFactionPosts(null);
            }).catch((error) => { })
    }

    const likePost = (item) => {
        const postid = item.id;
        const userid = userData.id
        let index = like.findIndex((x) => x === postid);
        if (item.isLiked > 0) {
            like.splice(index, 1);
            Service.delete({ url: `/activity/postsLike/${postid}`, body: JSON.stringify() })
                .then((response) => {
                    getAllFactionPosts(null);
                }).catch((error) => { })
        }
        else {
            like.push(item.id);
            const params = {
                postId: postid,
                userId: userid
            }
            Service.post({ url: '/activity/postsLike', body: JSON.stringify(params) })
                .then((response) => {
                    getAllFactionPosts(null);
                }).catch((error) => { })
        }
        setLike([...like]);
    }

    const dislikePost = (item) => {
        const postid = item.id;
        let index = dislikeButton.findIndex((x) => x === item.id);
        if (item.isDisliked > 0) {
            dislikeButton.splice(index, 1);
            Service.delete({ url: `/activity/postsDislike/${postid}`, body: JSON.stringify() })
                .then((response) => {
                    getAllFactionPosts(null);
                }).catch((error) => { })
        }
        else {
            dislikeButton.push(item.id);
            const params = {
                postId: postid
            }
            Service.post({ url: '/activity/postsDislike', body: JSON.stringify(params) })
                .then((response) => {
                    getAllFactionPosts(null);
                }).catch((error) => { })
        }
        setDislikeButton([...dislikeButton]);
    }

    const likeComment = (item) => {
        const commentid = item.id;
        const userid = userData.id
        let index = likeCommentButton.findIndex((x) => x === item.id);
        if (item.isliked > 0) {
            likeCommentButton.splice(index, 1);
            Service.delete({ url: `/activity/commentsLike/${commentid}`, body: JSON.stringify() })
                .then((response) => {
                    commentPost(item.post_id, false);
                }).catch((error) => { })
        }
        else {
            likeCommentButton.push(item.id);
            const params = {
                commentId: commentid,
                userId: userid
            }
            Service.post({ url: '/activity/commentsLike', body: JSON.stringify(params) })
                .then((response) => {
                    commentPost(item.post_id, false);
                }).catch((error) => { })
        }
        setLikeCommentButton([...likeCommentButton]);
    }

    const onAddSubmit = (data) => {

        const answer = editorState.getCurrentContent().hasText();

        if ((answer === false) && (resultVideoFiles === undefined || resultVideoFiles === "" || resultVideoFiles === null) && (resultFiles === undefined || resultFiles === "" || resultFiles === null)) {
            return false
        }

        const GroupID = factionData.id
        const ans = stateToHTML(editorState.getCurrentContent(), options)

        const Message = resultFiles && resultVideoFiles && `${answer ? ans : ""}[/1319/]${resultFiles.filePath}[/1319/]${resultVideoFiles}` || resultFiles && `${answer ? ans : ""}[/1319/]${resultFiles.filePath}[/1319/]${null}` || resultVideoFiles && `${answer ? ans : ""}[/1319/]${null}[/1319/]${resultVideoFiles}` || `${answer ? ans : ""}[/1319/]${null}[/1319/]${null}`

        const params = {
            groupId: GroupID,
            message: Message
        }
        Service.post({ url: '/activity/posts', body: JSON.stringify(params) })
            .then((response) => {
                getAllFactionPosts(null);
                setEditValue("")
                setValue("videolink", "")
                document.getElementById("fileInputId").value = null;
                document.getElementById("inputEditor").value = "";
                setResultFiles("")
                setVideoResultFiles("")
                setEditorState(() => EditorState.createEmpty())
                setSuggestions([])
            }).catch((error) => { })
    }

    const onEditSubmit = (data) => {

        const answer = editEditorState.getCurrentContent().hasText();
        if ((answer === undefined || answer === "" || answer === null) && (data.videoMessage === undefined || data.videoMessage === "" || data.videoMessage === null) && (resultFiles === undefined || resultFiles === "" || resultFiles === null) && (editValue === undefined || editValue === "" || editValue === null || editValue === "null")) {
            return false
        }
        const ans = stateToHTML(editEditorState.getCurrentContent(), options)
        const Message = `${answer && answer ? ans : ""}[/1319/]${resultFiles && resultFiles.filePath ? resultFiles.filePath : editValue}[/1319/]${data.videoMessage ? data.videoMessage : null}`
        const params = {
            message: Message
        }
        Service.patch({ url: `/activity/posts/${editIndex}`, body: JSON.stringify(params) })
            .then((response) => {
                editToggle();
                getAllFactionPosts(null);
                setResultFiles("")
                setVideoResultFiles("")
                setEditValue("")
                setValue("videolink", "")
                document.getElementById("fileInput1").value = null;
                document.getElementById("editInputEditor").value = "";
                setVideoResultFiles("")
                setEditEditorState(() => EditorState.createEmpty())
            }).catch((error) => { })
    }

    const onDeleteSubmit = () => {
        Service.delete({ url: `/activity/posts/${deleteIndex.id}`, body: JSON.stringify() })
            .then((response) => {
                deleteToggle();
                getAllFactionPosts(null);
            }).catch((error) => { })
    }

    const onCommentSubmit = (data) => {
        const postid = commentIndex;
        const message = data.Comment;
        const params = {
            postId: postid,
            comment: message
        }
        const params1 = {
            postId: postid.post_id,
            comment: message
        }
        if (popup === "Edit") {
            Service.patch({ url: `/activity/comments/${postid.id}`, body: JSON.stringify(params1) })
                .then((response) => {
                    setValue("Comment", "")
                    commentPost(postid.post_id, false);
                    getAllFactionPosts(null);
                }).catch((error) => { })
        } else {
            Service.post({ url: '/activity/comments', body: JSON.stringify(params) })
                .then((response) => {
                    setValue("Comment", "")
                    commentPost(postid, false);
                    getAllFactionPosts(null);
                }).catch((error) => { })
        }
    }

    const onReportSubmit = () => {
        const params = {
            postId: reportIndex
        }
        Service.post({ url: '/activity/posts/report', body: JSON.stringify(params) })
            .then((response) => {
                reportToggle();
                showToastMessageSuccess("Post reported successfully")
            }).catch((error) => { })
    }

    const onVideoSubmit = (data) => {
        setVideoResultFiles(data.videolink)
        videoToggle()
    }

    const onEditFactionSubmit = (data) => {

        const groupname = data.GroupName
        const groupdescription = data.GroupDescription
        const Image = resultFiles && resultFiles.filePath ? resultFiles.filePath : editImage

        if (groupname === "") {
            return false
        }

        if (groupdescription === "") {
            return false
        }

        const params = {
            groupName: groupname,
            description: groupdescription,
            groupImage: Image
        }
        Service.patch({ url: `/activity/groups/${editCommuntiyIndex}`, body: JSON.stringify(params) })
            .then((response) => {
                setResultFiles("")
                editCommunityToggle();
                factionGroups(null);
            }).catch((error) => { })
    }
    const pinPost = (item) => {
        const params = {
            postId: item.id,
            userId: userData.id
        }
        Service.post({ url: `/activity/addPinPost`, body: JSON.stringify(params) })
            .then((response) => {
                if (response.status === 'success') {
                    showToastMessageError(response.message)
                }
                getAllFactionPosts(null);
            }).catch((error) => { console.log(error) })
    }
    const unPinPost = (item) => {
        const params = {
            postId: item.id,
            userId: userData.id
        }
        Service.delete({ url: `/activity/deletePinPost/${item.id}`, body: JSON.stringify(params) })
            .then((response) => {
                if (response.status === 'success') {
                    showToastMessageError(response.message)
                }
                getAllFactionPosts(null);
            }).catch((error) => { console.log(error) })
    }

    function navigate(event) {
        event.preventDefault()
        if (event.target.tagName === 'A') {
            history.push(event.target.getAttribute('href'))
        }
    }

    const userProfile = (item) => {
        if (item.user === userData.id) {
            history.push(`/home`)
        } else {
            history.push(`/home/${item.user}`)
        }
    }

    const userCommentProfile = (item) => {
        if (item.user_id === userData.id) {
            history.push(`/home`)
        } else {
            history.push(`/home/${item.user_id}`)
        }
    }

    return (
        <>{
            userData.r === "n" ? <div className="px-4 bg-right">
                <SearchInput />
                <Row>
                    <Col xl="3" lg="3" md="12" sm="12" xs="12" className="mt-4">
                        <div className="mx-sm-5 px-sm-5 mx-lg-0 px-lg-0">
                            <Menu />
                        </div>
                        <div className="d-none d-lg-block mt-lg-4 stickey">
                            <MenuItems />
                        </div>
                    </Col>
                    <Col xl="6" lg="6" md="12" sm="12" xs="12" >
                        <div className="middle-info-bg h-lg-100 p-3 p-sm-4 mt-4">
                            <h3 className="mb-1">Factions</h3>
                            {
                                pageLoading ? <div className="bg-primary p-3 m-3 text-center"><Spinner color="light" /></div> :
                                    adminData && adminData.length > 0 && adminData.map((item) => {
                                        return <Row className="bg-primary py-3 px-1 mx-1 my-3 m-sm-3">
                                            <Col xl="12" lg="12" md="12" sm="12" xs="12">
                                                <Link to={`/faction/${item.isFactionGroup}`}>
                                                    <Row>
                                                        <Col lg="3" md="3" sm="3" xs="12" className="text-sm-left">
                                                            {
                                                                item.image ? <img src={`${Config.MEDIA_URL}${item.image}`} alt="" /> : <Avatar key={item.id} size="45" colors={['#e14eca', '#00f2c3', "#5e72e4"]} />
                                                            }
                                                        </Col>
                                                        <Col lg="9" md="12" sm="12" xs="12">
                                                            <Row>
                                                                <Col lg="9" md="9" sm="10" xs="12">
                                                                    <h6 className="mb-1 mt-3 mt-lg-0">{item.name}</h6>
                                                                    <div className="d-flex">
                                                                        <p className="text-secondary mt-1">{item.description}</p>
                                                                    </div>
                                                                </Col>
                                                                <Col lg="3" md="3" sm="2" xs="12" className="d-flex align-items-center justify-content-end mt-2 mt-sm-0 toggle-icon">
                                                                    <UncontrolledDropdown className='dropdown-user nav-item nav-item ml-2' direction="down">
                                                                        <DropdownToggle href='/' tag='a' className='align-items-center' onClick={e => e.preventDefault()} >
                                                                            <img src={require('../../assets/img/Landing_Page/menu1.png')} alt="" />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-right optionpost">
                                                                            <DropdownItem to='#' onClick={() => editCommunityDetails(item)}>
                                                                                <span className='align-middle optionpostitem'>Edit Faction</span>
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Col>
                                        </Row>
                                    })
                            }

                            {/* <------- Edit Communtiy ------------> */}

                            <Modal isOpen={editCommunity} toggle={editCommunityToggle}>
                                <ModalHeader toggle={editCommunityToggle}>Edit Faction Image</ModalHeader>
                                <hr className="w-100 modal-line" />
                                <ModalBody>
                                    <Form onSubmit={handleSubmit(onEditFactionSubmit)}>
                                        <FormGroup>
                                            <Label for="groupname">
                                                Faction Name
                                            </Label>
                                            <Controller
                                                name="GroupName"
                                                control={control}
                                                id="groupname"
                                                render={({ field }) => <Input placeholder="Enter Group Name" type="text" {...field} />}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="groupdescription">
                                                Faction Description
                                            </Label>
                                            <Controller
                                                name="GroupDescription"
                                                control={control}
                                                id="groupdescription"
                                                render={({ field }) => <Input placeholder="Enter Group Description" type="text" {...field} />}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="photo">
                                                Update your Faction Photo
                                            </Label>
                                            <FileAvatar aspectRatio={4 / 2} setResultFiles={setResultFiles} />
                                            {editImage === "null" ? <></> : <img className="mt-4" src={`${Config.MEDIA_URL}${editImage}`} alt="" width={"100px"} height={"50px"}></img>}
                                        </FormGroup>
                                        <ModalFooter>
                                            <Button color="success" type="submit">
                                                Edit
                                            </Button>{' '}
                                            <Button color="secondary" onClick={editCommunityToggle}>
                                                Cancel
                                            </Button>
                                        </ModalFooter>
                                    </Form>
                                </ModalBody>
                            </Modal>
                        </div>
                    </Col>
                    <Col xl="3" lg="3" md="12" sm="12" xs="12" className="p-0 my-5 mt-sm-0">
                        <Likes />
                    </Col>
                </Row>
            </div > : <div className="px-4 bg-right">
                <SearchInput />
                <Row>
                    <Col xl="3" lg="3" md="12" sm="12" xs="12" className="mt-4">
                        <div className="mx-sm-5 px-sm-5 mx-lg-0 px-lg-0">
                            <Menu />
                        </div>
                        <div className="d-none d-lg-block mt-lg-4 stickey">
                            <MenuItems />
                        </div>
                    </Col>
                    <Col xl="6" lg="6" md="12" sm="12" xs="12" >
                        {
                            pageLoading && sectionLoading ? <div className="bg-primary2 p-3 m-3 text-center"><Spinner color="light" /></div> : <div className="middle-info-bg h-lg-100 p-1 p-xl-4 mt-4">
                                <div className="bg-primary m-2 m-sm-3">
                                    <img src={factionData.image ? `${Config.MEDIA_URL}${factionData.image}` : require('../../assets/img/Landing_Page/backimage.jpg').default} height={'fit-content'} width={'100%'} alt="" />
                                    <div className="d-flex flex-column flex-sm-row justify-content-between mx-4 py-4">
                                        <div>
                                            <h3 className="mb-1 text-center text-sm-left">{factionData.name}</h3>
                                            <div>
                                                <p className="text-center text-sm-left">{factionData.description}</p>
                                            </div>
                                        </div>
                                        {/* <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center">
                                        <div className="text-center mr-3">
                                            <p>Members -<span className="text-white">{factionData.count}</span></p>
                                        </div>
                                    </div> */}
                                    </div>
                                </div>

                                <div className="bg-primary m-2 m-sm-3">
                                    <Form onSubmit={handleSubmit(onAddSubmit)}>
                                        <Row className="d-flex align-items-center justify-content-center p-3">
                                            <Col xl="12" lg="12" md="12" sm="12">
                                                <Row className="d-flex flex-row justify-content-center align-items-center">
                                                    <Col xl="2" lg="2" md="2" sm="2" xs="12">
                                                        <div className={userData.avatar ? "avatar-img1" : "avatar-img1-original"}>
                                                            <img src={userData.avatar ? `${Config.MEDIA_URL}${userData.avatar}` : require('../../assets/img/Landing_Page/logo.png')} className="avatar-imginside" alt="" />
                                                        </div>
                                                    </Col>
                                                    <Col xl="10" lg="10" md="10" sm="10" xs="12">
                                                        <div
                                                            id="inputEditor"
                                                            className='editor ml-sm-2 ml-lg-0 ml-xl-2 mb-0'
                                                            onClick={() => {
                                                                ref.current.focus();
                                                            }}
                                                        >
                                                            <Editor
                                                                editorKey={'editor'}
                                                                editorState={editorState}
                                                                onChange={setEditorState}
                                                                plugins={plugins}
                                                                ref={ref}
                                                                placeholder="Start post to your profile"
                                                            />

                                                            <MentionSuggestions
                                                                open={open}
                                                                onOpenChange={onOpenChange}
                                                                suggestions={suggestions}
                                                                onSearchChange={onSearchChange}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg="12" md="12" sm="12" xs='12'>
                                                <div className="ml-sm-5 mt-2 row flex-wrap  align-items-center justify-content-center main-input-image">
                                                    <div className="col-sm-4 col-6 justify-content-center image-video-input d-flex align-items-center">
                                                        <img src={require('../../assets/img/Landing_Page/image.png')} /> <span className="text-secondary pl-2">Image</span>
                                                        <Input id="fileInputId" name="fileUpload" className="avatarinput2 cursor-pointer" type="file" onChange={(e) => fileUploading(e.target.files[0])} />
                                                    </div>
                                                    <div className="col-sm-4 col-6 justify-content-sm-start justify-content-center d-flex align-items-center" onClick={() => videoToggle()}>
                                                        <div className="cursor-pointer">
                                                        <img src={require('../../assets/img/Landing_Page/video-play.png')} /> <span className="text-secondary pl-2">Video</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 col-12 text-sm-left text-center mt-3 mt-md-0">
                                                        <Button className="modal-btn" disabled={loading ? true : false} ><h5 className="mb-0">Post</h5></Button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                                {
                                    pageLoading ? <div className="bg-primary p-3 m-2 m-sm-3 text-center"><Spinner color="light" /></div> :
                                        factionPost && factionPost.length > 0 ?
                                            factionPost && factionPost.length > 0 && factionPost.map((item, index) => {
                                                return <div className="bg-primary m-2 m-sm-3" id={item.id}>
                                                    <div className="d-flex align-items-center justify-content-between py-3 pl-3 pr-3">
                                                        <div className="d-flex align-items-center justify-content-start cursor-pointer" onClick={() => userProfile(item)}>
                                                            <div className={item.UserProfileImage ? "avatar-img1" : "avatar-img1-original"}>
                                                                <img src={item.UserProfileImage ? `${Config.MEDIA_URL}${item.UserProfileImage}` : require('../../assets/img/Landing_Page/logo.png')} className="avatar-imginside" alt="" />
                                                            </div>
                                                            <div className="ml-2">
                                                                <h4 className="mb-0">{item.userName ? item.userName : `USER ${item.user}`}</h4>
                                                                <span>Posted at {moment(item.date).format('LLL')}</span>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-row align-items-center justify-content-start">
                                                            <div className="mb-3">
                                                                {item.isPin > 0 && <AiOutlinePushpin color="#FFF" size={30} />}
                                                            </div>
                                                            <UncontrolledDropdown className='dropdown-user nav-item'>
                                                                <DropdownToggle href='/' tag='a' className='align-items-center' onClick={e => e.preventDefault()} >
                                                                    {
                                                                        (item.user === userData.id) ? <img src={require('../../assets/img/Landing_Page/menu1.png')} className="mr-3 mb-3" alt="" /> : <></>
                                                                    }
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-right optionpost">
                                                                    {item && item.isPin === 0 ? <DropdownItem to='#' onClick={() => pinPost(item)}>
                                                                        <span className='align-middle optionpostitem'>Pin Post</span>
                                                                    </DropdownItem> : <DropdownItem to='#' onClick={() => unPinPost(item)}>
                                                                        <span className='align-middle optionpostitem'>Un Pin Post</span>
                                                                    </DropdownItem>}
                                                                    <DropdownItem id={index} key={index} to='#' onClick={() => editPost(item)}>
                                                                        <span className='align-middle optionpostitem'>Edit Post</span>
                                                                    </DropdownItem>
                                                                    <DropdownItem to='#' onClick={() => deletePost(item)}>
                                                                        <span className='align-middle optionpostitem'>Delete Post</span>
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </div>
                                                    </div>
                                                    <p onClick={(e) => navigate(e)} dangerouslySetInnerHTML={{ __html: item.postMessage.split("[/1319/]")[0] }} className="mx-4 mb-4 text-capitalize text-justify"></p>
                                                    {
                                                        item.postMessage.split("[/1319/]")[1] === "null" || item.postMessage.split("[/1319/]")[1] === undefined ? <></> : <img src={`${Config.MEDIA_URL}${item.postMessage.split("[/1319/]")[1]}`} className="px-3 pb-3 image-width" height={'fit-content'} alt="" />
                                                    }
                                                    {
                                                        item.postMessage.split("[/1319/]")[2] === "null" || item.postMessage.split("[/1319/]")[2] === undefined ? <></> : <ReactPlayer className="px-3 image-width rounded" url={item.postMessage.split("[/1319/]")[2]} controls={true} width={"100%"} />
                                                    }
                                                    <Row className="mx-3 mt-4 actiongrid py-2 px-2">
                                                        <Col className="d-flex flex-sm-row flex-column justify-content-sm-between justify-content-center align-items-center" lg={4} md={4} sm={4}
                                                            xs={12}>
                                                            <a href="/" onClick={(e) => e.preventDefault()} className="actiontypes">{item.likes} {item.likes > 1 ? "Likes" : "Like"}</a>
                                                            <a href="/" onClick={(e) => e.preventDefault()} className="actiontypes">{item.dislikes} {item.dislikes > 1 ? "Dislikes" : "Dislike"}</a>
                                                        </Col>
                                                        <Col className="d-flex flex-sm-row flex-column justify-content-center justify-content-sm-end align-items-center" lg={8} md={8} sm={8} xs={12}>
                                                            <a href="/" onClick={(e) => { e.preventDefault(); commentPost(item.id) }} className="pr-md-5 actiontypes">{item.comments} {item.comments > 1 ? "Comments" : "Comment"}</a>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        item.postCmt && <div className="mx-3 mt-4 actiongrid d-flex justify-content-start align-items-center py-2 px-0">
                                                            <Col className="w-100" onClick={() => commentPost(item.id)}>
                                                                <Row className="align-items-center justify-content-center">
                                                                    <Col className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                                                        <div className={item.cmtUserProfile ? "avatar-img1" : "avatar-img1-original"}>
                                                                            <img src={item.cmtUserProfile ? `${Config.MEDIA_URL}${item.cmtUserProfile}` : require('../../assets/img/Landing_Page/logo.png')} className="avatar-imginside" alt="" />
                                                                        </div>
                                                                    </Col>
                                                                    <Col className="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                                                        <div className="comment-bg">
                                                                            <h6>{item.cmtUserName ? item.cmtUserName : "USER"}</h6>
                                                                            <span className="text-muted">{item.postCmt}</span>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </div>
                                                    }
                                                    <Row className="mt-3 pb-4 mx-1">
                                                        {
                                                            <><Col className="d-flex justify-content-center align-items-center cursor-pointer" onClick={() => likePost(item)}>
                                                                {
                                                                    item.isLiked > 0 ? <AiFillLike color="#00FF00" size={"2em"} /> : <AiOutlineLike size={"2em"} />
                                                                }
                                                                <h5 className={`ml-1 mb-0 ${item.isLiked > 0 ? "like-color" : "text-muted"}`}>Dope</h5>
                                                            </Col>
                                                                <Col className="d-flex justify-content-center align-items-center cursor-pointer" onClick={() => dislikePost(item)}>
                                                                    {
                                                                        item.isDisliked > 0 ? <AiFillDislike color="#FF0000" size={"2em"} /> : <AiOutlineDislike size={"2em"} />
                                                                    }
                                                                    <h5 className={`ml-1 mb-0 ${item.isDisliked > 0 ? "dislike-color" : "text-muted"}`}>Wack</h5>
                                                                </Col>
                                                                <Col className="d-flex justify-content-center align-items-center cursor-pointer" onClick={() => commentPost(item.id)}>
                                                                    <BiCommentMinus size={"2em"} />
                                                                    <h5 className="ml-1 mb-0 text-muted">Comment</h5>
                                                                </Col>
                                                                <Col className="d-flex justify-content-center align-items-center cursor-pointer" onClick={() => reportPost(item)}>
                                                                    <TbReportSearch size={"2em"} />
                                                                    <h5 className="ml-1 mb-0 text-muted">Report</h5>
                                                                </Col></>
                                                        }
                                                    </Row>
                                                </div>
                                            }) : <div className="bg-primary p-3 m-3 text-center">
                                                <h6 className="mb-0">No Posts yet</h6>
                                            </div>
                                }

                                {/* <------- Edit Modal ------------> */}

                                <Modal isOpen={editModal} toggle={editToggle}>
                                    <ModalHeader toggle={editToggle}>Edit Social Post</ModalHeader>
                                    <hr className="w-100 modal-line" />
                                    <ModalBody>
                                        <Form onSubmit={handleSubmit(onEditSubmit)}>
                                            <FormGroup>
                                                <Label for="message">
                                                    Message
                                                </Label>
                                                <div
                                                    id="editInputEditor"
                                                    className='editor ml-sm-2 ml-lg-0 ml-xl-2 mb-0'
                                                    onClick={() => {
                                                        editref.current.focus();
                                                    }}
                                                >
                                                    <Editor
                                                        editorKey={'editor'}
                                                        editorState={editEditorState}
                                                        onChange={setEditEditorState}
                                                        plugins={plugins1}
                                                        ref={editref}
                                                    />

                                                    <MentionSuggestions2
                                                        open={editOpen}
                                                        onOpenChange={onEditOpenChange}
                                                        suggestions={editSuggestions}
                                                        onSearchChange={onSearchEditChange}
                                                    />
                                                </div>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="photoMessage" >
                                                    Photo
                                                </Label><br />
                                                <div className="upload-btn-wrapper">
                                                    <button className="btn1 cursor-pointer"><RiImageAddFill size="1rem" className="mr-2" />Upload Image</button>
                                                    <Input id="fileInput1" name="fileUpload" className="cursor-pointer" type="file" onChange={(e) => fileUploading(e.target.files[0])} />
                                                </div><br />
                                                {editValue === "null" ? <></> : <img className="mt-4 main-image-preview" src={`${Config.MEDIA_URL}${editValue}`} alt="" width={"100px"} height={"50px"}></img>}
                                            </FormGroup>
                                            <FormGroup className="mt-4">
                                                <Label for="videoMessage">
                                                    Video
                                                </Label>
                                                <Controller
                                                    name="videoMessage"
                                                    control={control}
                                                    id="videoMessage"
                                                    render={({ field }) => <Input placeholder="Enter Video Link" type="text" {...field} />}
                                                />
                                            </FormGroup>
                                            <ModalFooter>
                                                <Button color="success" type="submit" disabled={loading ? true : false}>
                                                    Edit
                                                </Button>{' '}
                                                <Button color="secondary" onClick={editToggle}>
                                                    Cancel
                                                </Button>
                                            </ModalFooter>
                                        </Form>
                                    </ModalBody>
                                </Modal>

                                {/* <------- Delete Modal ------------> */}

                                <Modal isOpen={deleteModal} toggle={deleteToggle}>
                                    <ModalHeader toggle={deleteToggle}>Delete Social Post</ModalHeader>
                                    <hr className="w-100 modal-line" />
                                    <ModalBody>
                                        <span>Do you really want to delete the post ?</span>
                                        <ModalFooter>
                                            <Button color="success" type="submit" onClick={() => onDeleteSubmit()}>
                                                Delete
                                            </Button>{' '}
                                            <Button color="secondary" onClick={deleteToggle}>
                                                Cancel
                                            </Button>
                                        </ModalFooter>
                                    </ModalBody>
                                </Modal>

                                {/* <------- Comment Modal ------------> */}

                                <Modal isOpen={commentModal} toggle={commentToggle}>
                                    <ModalHeader toggle={commentToggle}>Comments</ModalHeader>
                                    <hr className="w-100 modal-line" />
                                    <div className="scrolling-enable1">
                                        <ModalBody>
                                            {
                                                commentsData && commentsData.length > 0 ? commentsData.map((item) => {
                                                    return <FormGroup>
                                                        <Row className="d-flex align-items-center" id={item.id}>
                                                            <Col lg={2} md={2} sm={2} xs={2} className='mb-3 d-flex cursor-pointer justify-content-center' onClick={() => userCommentProfile(item)}>
                                                                <div className={item.UserProfileImage ? "avatar-img3" : "avatar-img3-original"}>
                                                                    <img src={item.UserProfileImage ? `${Config.MEDIA_URL}${item.UserProfileImage}` : require('../../assets/img/Landing_Page/logo.png')} className="avatar-imginside" alt="" />
                                                                </div>
                                                            </Col>
                                                            <Col lg={7} md={7} sm={7} xs={7} className="justify-content-start p-0">
                                                                <Col className="comment-bg">
                                                                    <h6>{item.userName ? item.userName : `USER ${item.user_id}`}</h6>
                                                                    <span className="text-muted">{item.message}</span>
                                                                </Col>
                                                                <Col className="d-flex justify-content-start" lg={12}>
                                                                    <a href="/" onClick={(e) => e.preventDefault()} className="actiontypes">{item.likes} {item.likes > 1 ? "Likes" : "Like"}</a>
                                                                </Col>
                                                            </Col>
                                                            {
                                                                <Col lg={2} md={2} sm={2} xs={2} id="TooltipExample" className='cursor-pointer mb-3 text-center' onClick={() => likeComment(item)}>
                                                                    <Tooltip
                                                                        isOpen={tooltipOpen}
                                                                        target="TooltipExample"
                                                                        toggle={toggle}
                                                                    >
                                                                        Like Comment
                                                                    </Tooltip>
                                                                    {
                                                                        item.isliked > 0 ? <AiFillLike className="com-like-button" size={"35px"} /> : <AiOutlineLike className="com-like-button1" size={"35px"} />
                                                                    }
                                                                </Col>
                                                            }

                                                            {
                                                                (item.user_id === userData.id) ? <Col lg={1} md={1} sm={1} xs={1} className="p-0">
                                                                    <UncontrolledDropdown className='dropdown-user nav-item' direction="down">
                                                                        <DropdownToggle href='/' tag='a' className='align-items-center' onClick={e => e.preventDefault()} >
                                                                            <img src={require('.././../assets/img/Landing_Page/menu1.png')} className="mr-3 mb-3" alt="" />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu container="body" className="dropdown-menu-right optionpost">
                                                                            <DropdownItem to='#' onClick={() => editComment(item)}>
                                                                                <span className='align-middle optionpostitem'>Edit Comment</span>
                                                                            </DropdownItem>
                                                                            <DropdownItem to='#' onClick={() => deleteComment(item)}>
                                                                                <span className='align-middle optionpostitem'>Delete Comment</span>
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </Col> : <span></span>
                                                            }
                                                        </Row>
                                                    </FormGroup>
                                                }) : <div className="text-center"><span>No Comments yet</span></div>
                                            }
                                        </ModalBody>
                                    </div>
                                    <hr className="w-100 modal-line" />
                                    <ModalFooter className="py-0 main-modal-comment d-flex align-items-center justify-content-center">
                                        <Form onSubmit={handleSubmit(onCommentSubmit)}>
                                            {
                                                <Row className="d-flex align-items-center">
                                                    <Col lg={2} md={3} sm={3} xs={12} className="pl-sm-3"><div className={userData.avatar ? "avatar-img3" : "avatar-img3-original"}><img src={userData.avatar ? `${Config.MEDIA_URL}${userData.avatar}` : require('../../assets/img/Landing_Page/logo.png')} className="avatar-imginside" alt="" /></div></Col>
                                                    <Col lg={7} md={6} sm={6} xs={9} className='p-0 mt-2'>
                                                        <FormGroup>
                                                            <Controller
                                                                name="Comment"
                                                                control={control}
                                                                id="comment"
                                                                render={({ field }) => <Input placeholder="Write a Comment" type="text" {...field} />}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={3} md={3} sm={3} xs={3} className="text-right">
                                                        <Button color="success" type="submit">
                                                            Post
                                                        </Button>{' '}
                                                    </Col>
                                                </Row>
                                            }
                                        </Form>
                                    </ModalFooter>
                                </Modal>

                                {/* <------- Report Modal ------------> */}

                                <Modal isOpen={reportModal} toggle={reportToggle}>
                                    <ModalHeader toggle={reportToggle}>Report</ModalHeader>
                                    <hr className="w-100 modal-line" />
                                    <ModalBody>
                                        <Form onSubmit={handleSubmit(onReportSubmit)}>
                                            <FormGroup>
                                                <Label for="report">
                                                    Do you want to report this post ?
                                                </Label>
                                            </FormGroup>
                                            <ModalFooter>
                                                <Button color="success" type="submit">
                                                    Yes
                                                </Button>{' '}
                                                <Button color="secondary" onClick={reportToggle}>
                                                    No
                                                </Button>
                                            </ModalFooter>
                                        </Form>
                                    </ModalBody>
                                </Modal>

                                {/* <------- Video Upload Modal ------------> */}

                                <Modal isOpen={videoModal} toggle={videoToggle}>
                                    <ModalHeader toggle={videoToggle}>Video</ModalHeader>
                                    <hr className="w-100 modal-line" />
                                    <ModalBody>
                                        <Form onSubmit={handleSubmit(onVideoSubmit)}>
                                            <FormGroup>
                                                <Label for="videolink">
                                                    Enter Video Link
                                                </Label>
                                                <Controller
                                                    name="videolink"
                                                    control={control}
                                                    id="videolink"
                                                    render={({ field }) => <Input placeholder="Enter Video Link" type="text" {...field} />}
                                                />
                                            </FormGroup>
                                            <ModalFooter>
                                                <Button color="success" type="submit">
                                                    Add
                                                </Button>{' '}
                                                <Button color="secondary" onClick={videoToggle}>
                                                    Cancel
                                                </Button>
                                            </ModalFooter>
                                        </Form>
                                    </ModalBody>
                                </Modal>
                            </div>
                        }
                    </Col>
                    <Col xl="3" lg="3" md="12" sm="12" xs="12" className="p-0 my-5 mt-sm-0">
                        <Likes />
                    </Col>
                </Row>
            </div >
        }
        </>
    )
}

export default Faction