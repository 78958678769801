import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Input, UncontrolledPopover, PopoverHeader, PopoverBody, Form, FormGroup, Label, Button, Progress } from "reactstrap"
import { Service, Storage } from "services/service";
import Config from '../config/Config';
import { BiEdit } from 'react-icons/bi'
import { AiFillPlusCircle } from 'react-icons/ai'
import { Controller, useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import FileAvatar from "./FileAvatar.js"

export const Menu = () => {

    const history = useHistory()
    let userData = Storage.get("userData")

    const [display, setDisplay] = useState(false)
    const [userName, setUserName] = useState(false)
    const [factionData, setFactionData] = useState([])
    const [opacity, setOpacity] = useState("profile")
    const { control, handleSubmit, setValue } = useForm();

    const getAllUserData = () => {
        const params = {
            limit: 50,
            page: 1,
            order: "desc",
        }
        Service.post({ url: '/activity/posts/userProfileSearch', body: JSON.stringify(params) })
            .then((response) => {
                userData.reward = response.data.profile.totalRemarks
                Storage.set("userData", userData);
            }).catch((error) => {
            })
    }

    const badgeImage = () => {
        const percentageProgress = Storage.get("userData").reward
        switch(true) {
            case (Storage.get("userData").reward == 100):
                return "VerifiedDiamond"
            case (percentageProgress >= 75 && percentageProgress <= 99):
                return "VerifiedPlatinum"
            case (percentageProgress >= 50 && percentageProgress <= 74):
                return "VerifiedGold"
            case (percentageProgress >= 25 && percentageProgress <= 49):
                return "VerifiedSilver"
            case (percentageProgress >= 0 && percentageProgress <= 24):
                return "VerifiedBronze"
            default:
                return "Verified"    
        }
    }

    useEffect(() => {
        setValue("profileName", userData.name)
        setValue("username", userData.email)
        setValue("facebook", userData && userData.facebook && userData.facebook)
        setValue("twitter", userData && userData.twitter && userData.twitter)
        setValue("linkedin", userData && userData.linkedin && userData.linkedin)

        getAllUserData()
    }, [])

    useEffect(() => {
        viewFactionDetails()
    }, [])

    const viewFactionDetails = () => {
        Service.get({ url: `/activity/groups/factionGroup/${userData.faction}`, body: JSON.stringify() })
            .then((response) => {
                setFactionData(response.data)
            }).catch((error) => { })
    }

    const onImageSubmit = (filepath) => {
        if (filepath) {
            const Avatar = filepath
            const params = {
                avatar: Avatar
            }
            Service.patch({ url: '/user/profile', body: JSON.stringify(params) })
                .then((response) => {
                    userData.avatar = Avatar;
                    Storage.set("userData", userData);
                    history.push("/home")
                }).catch((error) => { })
        }
    }

    const onProfileSubmit = (data) => {
        const Name = data.profileName
        if (Name === userData.name) {
            return false
        }
        const params = {
            name: Name
        }
        Service.patch({ url: '/user/profile', body: JSON.stringify(params) })
            .then((response) => {
                userData.name = Name;
                Storage.set("userData", userData);
                history.push("/home")
                setDisplay(false)
            }).catch((error) => { })
    }

    const onUsernameSubmit = (data) => {
        const UserName = data.username.toLowerCase()
        if (UserName === userData.email) {
            return false
        }
        const params = {
            email: UserName
        }
        Service.patch({ url: '/user/profile', body: JSON.stringify(params) })
            .then((response) => {
                userData.email = UserName;
                Storage.set("userData", userData);
                history.push("/home")
                setUserName(false)
            }).catch((error) => { })
    }

    const onSocialLinkSubmit = (data) => {
        const facebookName = data.facebook ? data.facebook.toLowerCase() : ""
        const twitterName = data.twitter ? data.twitter.toLowerCase() : ""
        const linkedinName = data.linkedIn ? data.linkedIn.toLowerCase() : ""
        const params = {
            facebook: facebookName,
            twitter: twitterName,
            linkedin: linkedinName
        }
        Service.patch({ url: '/user/profile', body: JSON.stringify(params) })
            .then((response) => {
                userData.facebook = facebookName;
                userData.twitter = twitterName;
                userData.linkedin = linkedinName;
                Storage.set("userData", userData);
                history.push("/home")
            }).catch((error) => { })
    }


    return (
        <>
            <Row>
                <Col xl="12" lg="12" sm="12" md="12">
                    <div className="social-info-bg text-center pb-1 position-rel pt-4 pt-lg-4 pt-xl-0">
                        <img src={require('../assets/img/Landing_Page/Frame1.png')} className="image-width d-none d-xl-block" />
                        <div className={userData.avatar ? "avatar-img position-abs4 mt-4 mt-xl-0" : "avatar-img-original position-abs4"}>
                            <label className="profile-image-edit-icon p-0 flex-column" for="upload">
                                <BiEdit size={"25px"} />
                                <span>Change</span>
                                <span>Image</span>
                            </label>
                            <img src={userData.avatar ? `${Config.MEDIA_URL}${userData.avatar}` : require('../assets/img/Landing_Page/logo.png')} alt="avatar"
                                className="avatar-imginside" />
                            <FileAvatar aspectRatio={2 / 2} onImageSubmit={onImageSubmit} opacity={opacity} />
                        </div>
                        <img src={require(`../assets/img/Landing_Page/${badgeImage()}.png`)} className="position-abs1 mt-3 mt-xl-0 d-xl-block badge-img" />
                        <Row className="d-flex justify-content-center align-items-center pt-2 profile-edit-cont mb-3">
                            <h4 className="mt-lg-0 mb-0">{userData.name}</h4>
                            <BiEdit id="PopoverLegacy" size={"25px"} className="pl-2 profile-edit-icon" onClick={() => setDisplay(true)} />
                            <UncontrolledPopover
                                className={display ? "d-block" : "d-none"}
                                placement="bottom"
                                target="PopoverLegacy"
                                trigger="legacy"
                            >
                                <PopoverHeader>
                                    Edit Profile Name
                                </PopoverHeader>
                                <PopoverBody>
                                    <Form onSubmit={handleSubmit(onProfileSubmit)}>
                                        <FormGroup>
                                            <Label for="profilename">
                                                Edit Profile Name
                                            </Label>
                                            <Controller
                                                name="profileName"
                                                control={control}
                                                id="profilename"
                                                render={({ field }) => <Input placeholder="Enter Profile name" type="text" {...field} />}
                                            />
                                        </FormGroup>
                                        <div className="d-flex justify-content-center">
                                            <Button className="modal-btn" type="submit">
                                                Edit
                                            </Button>
                                        </div>
                                    </Form>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </Row>
                        <Row className="d-flex justify-content-center align-items-center profile-edit-cont mx-1 pb-1" >
                            <p className="text-secondary">@{userData.email}</p>
                            <BiEdit id="PopoverLegacy2" size={"25px"} className="pb-2 profile-edit-icon" onClick={() => setUserName(true)} />
                            <UncontrolledPopover
                                className={userName ? "d-block" : "d-none"}
                                placement="bottom"
                                target="PopoverLegacy2"
                                trigger="legacy"
                            >
                                <PopoverHeader>
                                    Edit Username
                                </PopoverHeader>
                                <PopoverBody>
                                    <Form onSubmit={handleSubmit(onUsernameSubmit)}>
                                        <FormGroup>
                                            <Label for="username">
                                                Edit Username
                                            </Label>
                                            <Controller
                                                name="username"
                                                control={control}
                                                id="username"
                                                render={({ field }) => <Input placeholder="Enter Username" type="text" {...field} />}
                                            />
                                        </FormGroup>
                                        <div className="d-flex justify-content-center">
                                            <Button className="modal-btn" type="submit">
                                                Edit
                                            </Button>
                                        </div>
                                    </Form>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </Row>
                        <div className="text-break px-3">
                            {
                                (userData) && (userData.facebook || userData.twitter || userData.linkedin) ? <div className="d-flex flex-column pb-3 align-items-center"><a className="social-link-display" href={userData.facebook} target={"_blank"}>{userData.facebook ? userData.facebook : null}</a><a className="social-link-display" href={userData.twitter} target={"_blank"}>{userData.twitter ? userData.twitter : null}</a><a className="social-link-display" href={userData.linkedin} target={"_blank"}>{userData.linkedin ? userData.linkedin : null}</a></div> : <></>
                            }
                        </div>
                        <div className="mb-2">
                            <a href="/" onClick={e => e.preventDefault()} className="social-links-add" id="PopoverLegacy5">Add Social Links <AiFillPlusCircle size={"1rem"} /></a>
                            <UncontrolledPopover
                                placement="bottom"
                                target="PopoverLegacy5"
                                trigger="legacy"
                            >
                                <PopoverHeader>
                                    Add Social Links
                                </PopoverHeader>
                                <PopoverBody>
                                    <Form onSubmit={handleSubmit(onSocialLinkSubmit)}>
                                        <FormGroup>
                                            <Label for="facebook">
                                                Enter Social Link 1
                                            </Label>
                                            <Controller
                                                name="facebook"
                                                control={control}
                                                id="facebook"
                                                render={({ field }) => <Input placeholder="Enter Social Link 1" type="text" {...field} />}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="twitter">
                                                Enter Social Link 2
                                            </Label>
                                            <Controller
                                                name="twitter"
                                                control={control}
                                                id="twitter"
                                                render={({ field }) => <Input placeholder="Enter Social Link 2" type="text" {...field} />}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="linkedin">
                                                Enter Social Link 3
                                            </Label>
                                            <Controller
                                                name="linkedin"
                                                control={control}
                                                id="linkedin"
                                                render={({ field }) => <Input placeholder="Enter Social Link 3" type="text" {...field} />}
                                            />
                                        </FormGroup>
                                        <div className="d-flex justify-content-center">
                                            <Button className="modal-btn" type="submit">
                                                Add
                                            </Button>
                                        </div>
                                    </Form>
                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>
                        <div className="border-bottom"></div>
                        <div className="py-3 px-3">
                            {
                                userData.r === "r" && <h6>{userData.zodiac} / {userData.wombat} / <Link className="fraction" to={`/faction`}>{factionData && factionData.name}</Link></h6>
                            }
                        </div>
                        <div className="px-3">
                            <div class="mb-5">
                                <Progress
                                    barClassName={userData.reward && userData.reward < 25 && "progress-bar-edit"}
                                    value={userData.reward ? userData.reward : 0}
                                    max={100}
                                    animated={true}
                                ><h5 className="mt-3">{userData.reward ? userData.reward : 0}%</h5></Progress>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

        </>
    )
}